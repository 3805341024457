/* You can add global styles to this file, and also import other style files */
// import environment variables

$framework-assets-root: '/framework-assets';
$framework-global-fonts-responsive: false;

// import the core styles (required)
@import '@tdui/td-ewp-ui-framework/framework-styles/framework.scss';
// import hybrid stylesheet (optional)
@import '@tdui/td-ewp-ui-framework/framework-styles/hybrid.scss';
